import { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../stylesheets/Hero-styles.scss';

const Hero = ({ props }) => {
  const { title, imgUrl, desc, buttonNav, style } = props;

  const navigate = useNavigate();
  const year = new Date().getFullYear();

  const [size, setSize] = useState([0, 0]);
  const updateSize = () => {
    if (window.dws.core.desktop()) {
      const widthVar = window.outerWidth * (2/5) >= 965 ? 965 : window.outerWidth * (2/5);
      setSize(widthVar);
    } else {
      setSize(window.outerWidth * (5/7));
    }
  }
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const linkFunction = () => {
    navigate(buttonNav);
  };

  return (
    <div id="hero-content" className={`hero-content ${style === 'reverse' ? 'hero-content-reverse' : 'hero-content-normal'}`}>
      <div className={style === 'reverse' ? 'hero-title-desc hero-title-desc-reverse' : 'hero-title-desc'}>
        <h1 id='hero-title'>{title}</h1>
        <p className='regular-paragraph'>{desc}</p>
        {buttonNav && (<button onClick={linkFunction} className='btn boka'>Kontakta oss</button>)}
      </div>
      {(style === 'reverse') && (
        <div className='hero-img-container'>
          <img style={{width: `${size}px`}} loading="lazy" className='hero-img-small' src={imgUrl} alt="hero pic" />
          {/* <div className='copyright'>{window.dws.core.companyTitle()} AB © - { year }</div> */}
        </div>
      )}
      {(style === 'normal') && (
        <div className='hero-img-container'>
          <img style={{width: `${size}px`}} loading="lazy" className='hero-img-small' src={imgUrl} alt="hero pic" />
          {/* <div className='copyright'>{window.dws.core.companyTitle()} AB © - { year }</div> */}
        </div>
      )}
    </div>
  );
}

export default Hero;

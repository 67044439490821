import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import setNamespace from "./helper-functions/setNamespace";

import LandingInit from './modules/landing/js/LandingInit';
import About from "./modules/about/js/About";
// import Galleri from "./modules/galleri/js/Galleri";
import Hire from "./modules/hire/js/Hire";
import NotFound from "./modules/not-found/js/not-found";

import Page from "./components/Page";

export default function App() {

  const core = {
    mobile: function () {
      return Boolean(window.innerWidth <= 483);
    },
    desktop: function () {
      return Boolean(window.outerWidth >= 935);
    },
    companyTitle: function () {
      return 'Media in focus'
    },
    version: function () {
      return '1.1.3'
    }
  }

  const feature = {
    develop: function () {
      return Boolean(window.location.hostname === 'localhost');
    }
  }

  setNamespace('core', core);
  setNamespace('feature', feature);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Page component={LandingInit} />} />
        <Route path="/om-oss" element={<Page component={About} />} />
        <Route path="/boka" element={<Page component={Hire} />} />
        {/* <Route path="/galleri" element={<Page component={Galleri} title={`${core.companyTitle().toUpperCase()} - Galleri`} />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
import '../stylesheets/Footer-styles.scss'
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div id='footer' className='footer'>
      <div id='links' className='footer-collection footer-collection-top'>
        <Link className='footer-link' to={'/om-oss'}>Om oss</Link>
        &nbsp; - &nbsp;
        <Link className='footer-link' to={'/boka'}>Kontakta oss</Link>
        {/* &nbsp; - &nbsp;
        <Link className='footer-link' to={'/galleri'}>Galleri</Link> */}
      </div>
      <div id='socials' className='footer-collection'>
        <span>Följ oss: </span>
        <b>
          <a href="https://www.instagram.com/mediainfocusab/" target="_blank" rel="noreferrer">Instagram</a>
        </b>
        , 
        <b>
          <a href="https://www.linkedin.com/company/media-in-focus/" target="_blank" rel="noreferrer"> LinkedIn</a>
        </b>
      </div>
      <div className='footer-collection'>
        Nå oss på: &nbsp;
        <Link
          to='#'
          onClick={(e) => {
              window.location.href = 'mailto:mediainfocusab@gmail.com';
              e.preventDefault();
          }}
        >
          mediainfocusab@gmail.com
        </Link>  
      </div>
      <div id='footer-copyright' className='footer-collection'>{ year } © - {window.dws.core.companyTitle()} göteborg AB</div>
    </div>
  );
}

export default Footer;

// import { useState } from "react";

import '../stylesheets/InputField-styles.scss';

// const InputField = ({ name, type, value, defaultChecked, placeholder, label, getValue }) => {
const InputField = (props) => {
  // const [input, setInput] = useState(value || '');
  // type = type || 'text';

  const handleChange = event => {
    props.onchange(event.target.value);
  }

  return (
    <>
      {props.label && (<label className="inputlabel">{props.label}</label>)}
      <input id={props.specialId} className="inputinput" onChange={handleChange} />
      {/* <input name={name} type={type} value={input} onInput={e => setInput(e.target.value)} defaultChecked={defaultChecked} placeholder={placeholder} /> */}
      {/* <input name={name} type={type} value={input} onInput={e => getValue(e.target.value)} defaultChecked={defaultChecked} placeholder={placeholder} /> */}
    </>
  );
}

export default InputField
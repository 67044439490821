import { useState, useLayoutEffect, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import '../stylesheets/Header-styles.scss';

const Header = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [lineHover, setLineHover] = useState();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const closeBurger = () => {
    document.querySelector('#shadow').classList.remove('shadow');
    document.querySelector('#shadow').classList.add('shadow-none');

    document.querySelector('#headerLinks').classList.remove('move');
    document.querySelector('#headerLinks').classList.add('move-back');

    document.querySelector('#line-1').classList.remove('line-1');
    document.querySelector('#line-1').classList.add('line-1-remove');
    document.querySelector('#line-2').classList.remove('line-2');
    document.querySelector('#line-2').classList.add('line-2-remove');
    document.querySelector('#line-3').classList.remove('line-3');
    document.querySelector('#line-3').classList.add('line-3-remove');
  };

  const hamburgerFunc = () => {
    if (burgerOpen) {
      closeBurger()
    } else {
      document.querySelector('#headerLinks').classList.remove('move-back');
      document.querySelector('#headerLinks').classList.add('move');
  
      document.querySelector('#shadow').classList.remove('shadow-none');
      document.querySelector('#shadow').classList.add('shadow');
      
      document.querySelector('#line-1').classList.remove('line-1-remove');
      document.querySelector('#line-1').classList.add('line-1');
      document.querySelector('#line-2').classList.remove('line-2-remove');
      document.querySelector('#line-2').classList.add('line-2');
      document.querySelector('#line-3').classList.remove('line-3-remove');
      document.querySelector('#line-3').classList.add('line-3');
    }
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      if (window.dws.core.desktop()) {
        if (document.querySelector('#headerLinks').classList[1] === 'move-back' || document.querySelector('#headerLinks').classList[1] === 'move') {
          document.querySelector('#headerLinks').classList.remove('move-back');
          document.querySelector('#headerLinks').classList.remove('move');
          document.querySelector('#shadow').classList.remove('shadow');
          document.querySelector('#shadow').classList.add('shadow-none');

          document.querySelector('#line-1').classList.remove('line-1');
          document.querySelector('#line-1').classList.remove('line-1-remove');
          document.querySelector('#line-2').classList.remove('line-2');
          document.querySelector('#line-2').classList.remove('line-2-remove');
          document.querySelector('#line-3').classList.remove('line-3');
          document.querySelector('#line-3').classList.remove('line-3-remove');
        }
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    setActiveTab(location.pathname)
  }, []);

  return (
    <>
      <div id="shadow" onClick={() => { closeBurger(); setBurgerOpen(!burgerOpen);}} className="shadow-none"></div>
      <div id='header' className='header'>
        <div className="hamburger"onClick={() => { hamburgerFunc(); setBurgerOpen(!burgerOpen);}} id='burger-btn'>
          <div id='line-1' className='line'></div>
          <div id='line-2' className='line'></div>
          <div id='line-3' className='line'></div>
        </div>
        <div id="headerLinks" className='header-link-menu'>
          <ul className='header-ul-list'>
            <li><Link className='footer-link' to={'/'}>Start</Link></li>
            <li><Link className='footer-link' to={'/om-oss'}>Om oss</Link></li>
            <li><Link className='footer-link' to={'/boka'}>Boka</Link></li>
            {/* <li><Link className='footer-link' to={'/galleri'}>Galleri</Link></li> */}
          </ul>
          <div className='header-link-desktop'>
            <Link 
              id='start-link' 
              onMouseEnter={e => {
                setLineHover('/')
              }}
              onMouseLeave={e => {
                setLineHover('')
              }}
              className='footer-link header-link-desktop' 
              to={'/'}
            >Start</Link>
            <div className={`${activeTab === '/' ? 'activeTab-line' : ''}${activeTab !== '/' && lineHover === '/' ? 'activeTab-line-hover' : ''}`}></div>
          </div>

          <span className='header-link-desktop cursor-default'>&nbsp; - &nbsp;</span>
          <div className='header-link-desktop'>
            <Link 
              id='om-oss-link' 
              onMouseEnter={e => {
                setLineHover('/om-oss')
              }}
              onMouseLeave={e => {
                setLineHover('')
              }}
              className='footer-link header-link-desktop' 
              to={'/om-oss'}
            >Om oss</Link>
            <div className={`${activeTab === '/om-oss' ? 'activeTab-line' : ''}${activeTab !== '/om-oss' && lineHover === '/om-oss' ? 'activeTab-line-hover' : ''}`}></div>
          </div>

          <span className='header-link-desktop cursor-default'>&nbsp; - &nbsp;</span>
          <div className='header-link-desktop'>
            <Link 
              id='boka-link'
              onMouseEnter={e => {
                setLineHover('/boka')
              }}
              onMouseLeave={e => {
                setLineHover('')
              }} 
              className='footer-link header-link-desktop' 
              to={'/boka'}
            >Kontakta oss</Link>
            <div className={`${activeTab === '/boka' ? 'activeTab-line' : ''}${activeTab !== '/boka' && lineHover === '/boka' ? 'activeTab-line-hover' : ''}`}></div>
          </div>

          {/* <span className='header-link-desktop cursor-default'>&nbsp; - &nbsp;</span>
          <div className='header-link-desktop'>
            <Link 
              id='galleri-link' 
              onMouseEnter={e => {
                setLineHover('/galleri')
              }}
              onMouseLeave={e => {
                setLineHover('')
              }}
              className='footer-link header-link-desktop' 
              to={'/galleri'}
            >Galleri</Link>
            <div className={`${activeTab === '/galleri' ? 'activeTab-line' : ''}${activeTab !== '/galleri' && lineHover === '/galleri' ? 'activeTab-line-hover' : ''}`}></div>
          </div> */}
        </div>
        
        <div className='header-link-desktop2'>
          <Link className={'footer-link'} to={'/'}><h1 id='header-text'>{window.dws.core.companyTitle().toUpperCase()}</h1></Link>
          <h2 className='header-subheading'>Professionella foton och videor för Dig och Ditt företag</h2>
        </div>
      </div>
    </>
  );
}

export default Header;
import { Helmet } from "react-helmet";
const { hostname } = require('./texts.json');

const MetaContainer = ({ title, description, imageUrl, imageAlt }) => {
  const myInterval = setInterval(setTitle, 100);

  function setTitle() {
    if (document.title === 'undefined' && title !== undefined) {
      document.title = `MEDIA IN FOCUS - ${title}`;
      myStopFunction();
    }
  };
  function myStopFunction() {
    clearInterval(myInterval);
  };

  return (
    <Helmet>
      {title !== undefined ? (<title>{`MEDIA IN FOCUS - ${title}`}</title>) : (<title>MEDIA IN FOCUS</title>)}
      <meta property="og:title" content={title !== undefined ? `MEDIA IN FOCUS - ${title}` : 'MEDIA IN FOCUS'} />
      
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={hostname + imageUrl} />
      <meta property="og:url" content={hostname + window.location.pathname + window.location.search} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content={imageAlt} />
    </Helmet>
  )
}

export default MetaContainer;
import { useEffect } from "react";

const Page = ({ title, component }) => {
  useEffect(() => {
    document.title = title
  });

  const PageComponent = component;
  return (
    <PageComponent />
  );
}

export default Page;

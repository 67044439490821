import Footer from "../../../components/footer/js/Footer";
import Header from "../../../components/header/js/Header";
import Hero from "../../../components/hero/js/Hero";
import MetaContainer from "../../../helper-functions/MetaContainer";
import MultipleVerticalImages from "../../../components/multiple-vertical-images/js/MultipleVerticalImages";

import '../stylesheets/LandingInit.scss';

const LandingInit = () => {
  const welcomeProps = {
    title: 'Din verksamhet förtjänar professionella bilder',
    imgUrl: 'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/odintak.jpg?alt=media&token=3f5c708e-37e3-4c79-a574-52d6ecd41b92',
    desc: 'Vi på media in focus finns för att hjälpa din verksamhets image att växa genom professionella foton och videor.',
    buttonNav: '/boka',
    style: 'normal'
  };

  const contentProps = {
    title: 'Ett perspektiv med liv och rörelse',
    imgUrl: 'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/Utkiken%20m%C3%B6rk%20-alex.jpg?alt=media&token=d051d20f-9acf-4060-8778-c7c3d8bd1cae',
    desc: 'En bild med fågelperspektiv ger en bra översikt på miljön. Fågelperspektivet är uppnås enklast med hjälp av drönare. Bilderna ger möjlighet för ett nytt perspektiv på även de mest vardagliga motiv.',
    style: 'reverse'
  };

  const läsöContent1 = {
    title: 'Ditt företag, dina visioner',
    imgArr: [
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-bokhylla.jpg?alt=media&token=3793a619-b133-4dbc-90c9-486b4621fd96',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-o%CC%88ltapp1l.jpg?alt=media&token=dc581369-7b0d-4cd7-aefc-6e5da03da07b',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-schackbra%CC%88de.jpg?alt=media&token=aed616e6-d40f-4262-bd59-298bfb93c0a5'
    ],
    // imgUrl: 'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/8K7A0593-Edit.jpg?alt=media&token=3bd5cb8c-8e50-49af-a595-f951ec06e920',
    desc: 'Vi stöttar och peppar dig hela vägen igenom processen för att du ska nå fram med dina önskemål så när vi väl går skilda vägar är vi båda parter nöjda med resultatet.',
    style: 'reverse'
  };

  const läsöContent2 = {
    title: 'Från start till mål!',
    imgArr: [
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-marstrand.jpg?alt=media&token=28ccd72f-1f61-4033-88f4-53fc6169c1f1',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-ma%CC%8Altid.jpg?alt=media&token=c900901d-7744-42ca-8d13-b6b9c0895dc7',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/la%CC%88so%CC%88-oslo.jpg?alt=media&token=db4454c1-5e84-4890-98c0-f6a1f97f3167'
    ],
    // imgUrl: 'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/8K7A0593-Edit.jpg?alt=media&token=3bd5cb8c-8e50-49af-a595-f951ec06e920',
    desc: 'Vi finns med dig från början till slut och kollar av med dig så att produkterna vi levererar inte bara matchar våra standards utan även tillfredställer dina behov och önskemål.',
    style: 'reverse'
  };
  const läsöContent3 = {
    title: 'Ljus, ljud och miljö',
    imgArr: [
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/odin-tjejpar.jpg?alt=media&token=4f194adb-7355-41a4-badf-bf9343ce829b',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/odin-ovan.jpg?alt=media&token=25d764e0-7382-4071-b877-c91104df85cd',
      'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/odin-sprakparty.jpg?alt=media&token=1d8cfe2e-4c82-4872-93d4-a12e73cfa32d'
    ],
    // imgUrl: 'https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/8K7A0593-Edit.jpg?alt=media&token=3bd5cb8c-8e50-49af-a595-f951ec06e920',
    desc: 'Vi på Media in Focus jobbar hårt för att skapa en miljö med rätt ljussättning som vi fångar med vår digitala media kunskap.',
    style: 'normal'
  };

  const handleScroll = event => {
    console.log('scrollTop: ', event.currentTarget.scrollTop);
    console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };

  return (
    <div
      onScroll={handleScroll}
    >
      <Header></Header>
      <div>
        <MetaContainer
          description="Vi på media in focus är drönarfotografer, och kamera experter från Göteborg. Vi finns för att hjälpa din verksamhets image att växa genom professionella foton och videor med både systemkameror och drönare."
          imageUrl="./MIF.png"
          imageAlt="Media in focus Professionella foton och videor för Dig och Ditt företag"
        />
        <main className="main-content">
          <Hero props={welcomeProps}></Hero>
          <MultipleVerticalImages props={läsöContent1} />

          <MultipleVerticalImages props={läsöContent2} />
          <MultipleVerticalImages props={läsöContent3} />
          <Hero props={contentProps}></Hero>

        </main>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default LandingInit;
import { useState, useLayoutEffect, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import '../stylesheets/MultipleVerticalImages-styles.scss';

const MultipleVerticalImages = ({ props }) => {
  const { title, imgUrl, imgArr, desc, buttonNav, style } = props;

  const navigate = useNavigate();
  const year = new Date().getFullYear();

  const linkFunction = () => {
    navigate(buttonNav);
  };

  console.log(imgArr.length);

  return (
    <div id="mvi-content" className={`mvi-content ${style === 'reverse' ? 'mvi-content-reverse' : 'mvi-content-normal'}`}>
      <div className={style === 'reverse' ? 'mvi-title-desc mvi-title-desc-reverse' : 'mvi-title-desc'}>
        <h1 id='mvi-title'>{title}</h1>
        <p className='regular-paragraph'>{desc}</p>
        {buttonNav && (<button onClick={linkFunction} className='btn boka'>Kontakta oss</button>)}
      </div>
      {(style === 'reverse') && (
        <div className='mvi-img-container'>
          {imgArr.length === 1 ? (
            <img loading="lazy" className='mvi-img-small mvi-img-small-single' src={imgUrl} alt="mvi pic" />
          ) : (
            imgArr.map((imageUrl, index)=>{
              return (
                <img loading="lazy" key={index} className='mvi-img-small' src={imageUrl} alt="mvi pic" />
              )
            })
          )}
        </div>
      )}
      {(style === 'normal') && (
        <div className='mvi-img-container'>
          {imgArr.length === 1 ? (
            <img loading="lazy" className='mvi-img-small mvi-img-small-single' src={imgUrl} alt="mvi pic" />
          ) : (
            imgArr.map((imageUrl, index)=>{
              return (
                <img loading="lazy" key={index} className='mvi-img-small' src={imageUrl} alt="mvi pic" />
              )
            })
          )}
        </div>
      )}
    </div>
  );
}

export default MultipleVerticalImages;

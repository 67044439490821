import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MetaContainer from '../../../helper-functions/MetaContainer';

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  },[])
  return (
    <MetaContainer
      title="404"
      description="Sidan du försöker besöka finns tyvärr inte och kan därför inte visas."
      imageUrl="./MIF.png"
      imageAlt="Media in focus Professionella foton och videor för Dig och Ditt företag"
    />
    );
}

export default NotFound;

import Header from "../../../components/header/js/Header";
import Footer from "../../../components/footer/js/Footer";
import MetaContainer from "../../../helper-functions/MetaContainer";

import { useNavigate } from 'react-router-dom';

import '../stylesheets/About.scss';
const { shortCompanyName } = require('../../../helper-functions/texts.json');

const companyDescription = `Vi på ${shortCompanyName} är ett mediaföretag som jobbar med såväl drönare som kameror och även redigering av foton och videor. Vi levererar därmed inte bara foton och videor utan vi ger även möjligheten till dig som kund att få materialet färdigredigerat med ljus och färg i tankarna. Allt för att du och ditt företag ska kunna växa eran digitala image.`;

const About = () => {
  const navigate = useNavigate();
  
  const linkFunction = (to) => {
    navigate(to)
  };

  return (
    <>
      <Header />
      <div className="main-content">
      <div className="">
      <MetaContainer title="Om oss" description={companyDescription} imageUrl="./MIF.png" imageAlt="Media in focus Professionella foton och videor för Dig och Ditt företag" />
        <h1 className="">Våra drönarpiloter</h1>
        <div className="pilot-big-container">
          <div className="pilot-container">
            <div className="profile-circle card">
              <img className="profile-circle-image" src="https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/mig%20sja%CC%88lv%203-Edit.jpg?alt=media&token=b91eda18-a7c5-4b19-827b-b56b43453b91" alt="" />
            </div>
            <p className="pilot-name mb-0">Simon</p>
            <p className="pilot-name-desc mt-0 mb-0">Fotograf & Redigerare</p>
          </div>
          <div className="pilot-container">
            <div className="profile-circle card">
              <img className="profile-circle-image" src="https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/8K7A2711-Edit%20(1).jpg?alt=media&token=5689a844-bd78-471b-835d-5caa1987b157" alt="" />
            </div>
            <p className="pilot-name mb-0">Alexander</p>
            <p className="pilot-name-desc mt-0 mb-0">FPV Pilot & Teknik ansvarig</p>
          </div>
          <div className="pilot-container">
            <div className="profile-circle card">
              <img className="profile-circle-image" src="https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/kjeppe%2026-07-2022.jpg?alt=media&token=756c24b9-baa9-488d-91f6-2f998f0430f9" alt="" />
            </div>
            <p className="pilot-name mb-0">Jesper</p>
            <p className="pilot-name-desc mt-0 mb-0">Drönarpilot & Redigerare</p>
          </div>
        </div>
      </div>
      <h1 className="about-h1">Vilka är vi?</h1>
        <div className="card regular-paragraph">
          <p className="mt-0">
            {companyDescription}
          </p>
          <p className="mb-0">
            Vi är väldigt stolta över att kunna erbjuda en nära kontakt med dig som kund. Vi ser till att allt från inspelning och fotografering till slutgilltigprodukt med godkännande från lantmäteriet är klart innan vi skickar iväg det till Dig. Vi vill gärna ha dig med oss under processen för att Du ska vara säker på att slutprodukten är något som känns rätt för dig och dina behov.
          </p>
          <p>
            Vi tar lagar och regler på högsta allvar och har därför krav på att alla våra drönarpiloter är licensierade enligt de senaste reglerna från trafikverket.

            Vi på företaget är även väl upplysta och håller oss uppdaterade på GDPR regulationer. Vi har även kontakt med lantmäteriet för att se till att våran media inte är skadlig för Sveriges säkerhet.
          </p>
          <h2 className="mb-0">Vår vision!</h2>
          <ul className="mt-0">
            <li>Hålla en nära kundkontakt</li>
            <li>Låta kunden ge input för att upplevelsen skall bli så bra som möjligt</li>
          </ul>

          {/* <h2 className="mb-0">Intresserad?</h2>
          <p className="mt-0 mb-0">Klicka gärna in på vårat galleri för att se vad vi sysslar med.</p>
          <button onClick={() => linkFunction('/galleri')} className='btn'>Galleri</button> */}

        </div>

        <div className="card regular-paragraph">
          <h2 className="mt-0">Kontakta oss</h2>
          <p className="mt-0">
            Känner du dig sugen på att träffa oss eller har funderingar? Tveka inte på att kontakta oss!
          </p>
          <button onClick={() => linkFunction('/boka')} className='btn'>Kontakta oss</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;

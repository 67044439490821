import emailjs from '@emailjs/browser';

import { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';

import Header from "../../../components/header/js/Header";
import Footer from "../../../components/footer/js/Footer";
import InputField from "../../../components/input-field/js/InputField";
import MetaContainer from '../../../helper-functions/MetaContainer';

import '../stylesheets/Hire-styles.scss';
const { shortCompanyName } = require('../../../helper-functions/texts.json');

const Hire = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [submitContactStatus, setSubmitContactStatus] = useState('');
  const [sumbitLoading, setSumbitLoading] = useState(false);

  const textarea = useRef();

  const onInputChange = (data, type) => {
    if (type === 'name') {
      setName(data);
    }
    if (type === 'email') {
      setEmail(data);
    }
  };

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = () => {
    if (name.length <= 0) {
      return 111;
    }
    return name;
  };

  const validateInput = () => {
    const validatedName = validateName();
    const validatedEmail = validateEmail();
    
    if (validatedName === 111) {
      setNameError('Namn kan inte vara tomt');
      return 'Namn kan inte vara tomt';
    }
    setNameError('');

    if (!validatedEmail) {
      setEmailError('Email ogiltig');
      return 'Email ogiltig'
    }
    setEmailError('');

    const contactObj = {
      isValid: true,
      name: name,
      email: email,
      message: textarea.current.innerText
    }
    return contactObj;
  }

  const submitForm = () => {
    setSubmitContactStatus('');
    const validatedInputs = validateInput();
    if (validatedInputs.isValid && validatedInputs.isValid !== undefined) {
      setSumbitLoading(true);

      // Loading thing
      setTimeout(function(){
        document.getElementById('loading-line1').style.animation = 'loading-bigger 1.5s infinite linear'
      }, 0);
      setTimeout(function(){
        document.getElementById('loading-line2').style.animation = 'loading-bigger 1.5s infinite linear'
      }, 500);
      setTimeout(function(){
        document.getElementById('loading-line3').style.animation = 'loading-bigger 1.5s infinite linear'
      }, 1000);
      // service_9y1g9kt
      emailjs.send('service_9y1g9kt', 'template_wkf8jet', validatedInputs, 'SnQ9Hokmt0-FJq_Ur')
      .then((result) => {
        if (result.text) {
          // Reset state
          setName('');
          setEmail('');
          
          // Reset UI
          document.getElementById('nameInput').value = '';
          document.getElementById('emailInput').value = '';
          document.getElementById('custom-textarea').innerText = '';
          document.getElementById('submit-email').blur(); // Removes focus from submit button
  
          setSumbitLoading(false);
          setSubmitContactStatus('Tack för ditt mail, återkopplar med dig så snart som möjligt!');
        }
        console.log(result.text);
      }, (error) => {
        setSumbitLoading(false);
        setSubmitContactStatus('Ojsan, något gick snett på våran sidan. Vänligen försök igen senare.');
        console.log(error.text);
      });
    }
  }


  return (
    <>
      <Header />
      <MetaContainer
        title="Kontakta Oss"
        description={`Kontakta oss på ${shortCompanyName} via mediainfocusab@gmail.com eller genom vårat kontakt formulär och låt oss hjälpa dig växa din verksamhet med hjälp av våran kompetens inom skapande av digitalmedia`}
        imageUrl="./MIF.png"
        imageAlt="Media in focus Professionella foton och videor för Dig och Ditt företag"
      />
      <div className="main-content">
        <div className="form-container">
          <div className="contentArea">
            <img className="hire-image" src="https://firebasestorage.googleapis.com/v0/b/dronare.appspot.com/o/simonsavann.jpg?alt=media&token=3bc5e0c2-20e2-4c10-81d9-771d573ce968" alt="Göteborgs nattid" />
            <div className="hire-image-overlay">Låt oss hjälpa dig växa din verksamhet med hjälp av våran kompetens inom skapande av digitalmedia</div>
          </div>
          <div className="formArea">
            <h1>Kontakta Oss</h1>
            <div className="inputContainer">
              <InputField specialId='nameInput' data={name} label='NAMN *' onchange={(e) => { onInputChange(e, 'name') }}/>
              {nameError && (<p className="error-text">{nameError}</p>)}
            </div>
            <div className="inputContainer">
              <InputField specialId='emailInput' data={email} label='EMAIL *' onchange={(e) => { onInputChange(e, 'email') }}/>
              {emailError && (<p className="error-text">{emailError}</p>)}
            </div>
            <div className="inputContainer">
              <label className="inputlabel">MEDDELANDE</label>
              <div id='custom-textarea' className="custom-textarea inputinput" contentEditable="true" ref={textarea}></div>
            </div>
            <div className="contact-minor-container">
              <button onClick={submitForm} id='submit-email' className='btn'>SKICKA</button>
              {sumbitLoading && (
                <>
                  <div className='loadingContiner'>
                    <div id='loading-line1' className="loading-line"></div>
                    <div id='loading-line2' className="loading-line"></div>
                    <div id='loading-line3' className="loading-line"></div>
                  </div>
                  <p className='contact-submitstatus'>Skickar...</p>
                </>
              )}
              <p className='contact-submitstatus'>{submitContactStatus}</p>
            </div>
            <p className='mb-0 mail-contact-info'>
              Det går även att nå oss på
              <Link
                className='mail-contact-info'
                to='#'
                onClick={(e) => {
                    window.location.href = 'mailto:mediainfocusab@gmail.com';
                    e.preventDefault();
                }}
              >
                mediainfocusab@gmail.com
              </Link>  
            </p>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default Hire;
